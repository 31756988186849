<template>
    <div class="product-list-wrapper" style="min-height: 80vh;">
        <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
            <div class="container d-flex align-items-center">
                <ol class="breadcrumb mt-3">
                    <li class="breadcrumb-item">
                        <router-link to="/" style="color:#6c757d;">
                            {{ 'Home' }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item" v-if="product.category">
                        <router-link style="color:#6c757d;" :to="{name: 'category', params: { cat: product.category.slug }}">
                        {{ product.category.name }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item" v-if="product.subcategory">
                        <router-link style="color:#6c757d;"
                            :to="{name: 'category', params: { cat: product.category.slug, sub: product.subcategory.slug, }}">
                        {{ product.subcategory.name }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item" v-if="product.subsubcategory">
                        <router-link style="color:#6c757d;"
                            :to="{name: 'category', params: { cat: product.category.slug, sub: product.subcategory.slug, sub_sub: product.subsubcategory.slug,}}">
                        {{ product.subsubcategory.name }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item" aria-current="page" v-if="product.name"><a> {{ product.name }}</a></li>
                </ol>
            </div>
        </nav>
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-lg-9 p-md-4 bg-white">
                    <div class="row">
                        <div class="col-lg-5 lg-mr-b">
                            <div data-v-8bb313ea="" class="image-magnifier w-100 border"
                                style="position: relative; cursor: move;">
                                <image-magnifier :src="current_image?current_image:showImage(product.thumbnail_img)"
                                                :zoom-src="current_image?current_image:showImage(product.thumbnail_img)"
                                                width="100%"
                                                height="320px"
                                                zoom-width="500"
                                                class="w-100 border"
                                                zoom-height="320">
                                </image-magnifier>
                                <!-- <div class="image-magnifier__mask"
                                    style="position: absolute; width: 100px; height: 100px; opacity: 0.5; background-color: rgb(64, 158, 255); left: 0px; top: 0px; transform: translate(150.984px, 98.5px); will-change: transform; pointer-events: none; z-index: 1000; visibility: hidden;"></div>
                                <div class="image-magnifier__zoom"
                                    style="width: 500px; height: 320px; position: absolute; top: 0px; overflow: hidden; z-index: 1000; left: 260.984px; display: none;">
                                    <img src=""
                                        style="width: 1254.92px; height: 1024px; will-change: transform; transform: translate(-754.922px, -315.2px);">
                                </div> -->
                            </div>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item p-1 mr-1 mt-1" v-for="(image,index) in jsonParse(product.photos)" :key="index" @click="imageChange(showImage(image))">
                                <img :src="showImage(image)" alt="" class="img-fluid rounded" width="40px" height="60px">
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-7">
                            <div class="product-details">
                                <h2>{{ product.name }}</h2>
                                   
                                  <b-row>
                                    <b-col cols="3">
                                      <span class="text-danger">
                                        <b-input-group size="sm">
                                          
                                          <b-form-rating
                                            class="col-sm-6"
                                            variant="danger"
                                            inline
                                            no-border
                                            v-model="rating"
                                            readonly
                                          ></b-form-rating>
                                        </b-input-group> </span
                                      >
                                      
                                    </b-col>
                                      
                                  </b-row>
                                <span style="font-size: 18px;">
                                    <span class="text-danger">   {{ reviewCount() }}</span> 
                                {{ $t("message.single_product.review") }} <span class="text-danger">{{ product.num_of_sale }}</span> {{ $t("message.single_product.buyer") }}</span>
                                <div class="des float-right" :style="(isAuthenticated && checkFavorite())?'color: red':''"
                                    style="font-size: 18px" @click="favorite()">
                                  <i class="fa fa-heart fav" style="float: right;"></i>
                                </div>
                                <div class="clearfix"></div>
                                <hr>
                                <p class="product-sort-description">{{ product.sort_desc }}</p>
                                <table class="table table-borderless" v-if="flash_deal===null && product.discount_variation==1">
                                <template>
                                    <tr>
                                    <td style="text-align: center;padding: 2px" v-for="(variation,index) in product.discount_variation_data" :key="index">
                                        {{ ' >= ' + (variation.min_qty) }} {{ product.unit ? product.unit.name : null }}
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style="text-align: center" v-for="(variation,key) in product.discount_variation_data" :key="key">
                                    <span
                                        :style="variation.min_qty<=quantity?product.discount_variation_data.length==(key+1)?'color:red':quantity<product.discount_variation_data[(key+1)].min_qty?'color:red':'':''">
                                    <strong> {{ variation.percent_off }} % off</strong></span></td>
                                    </tr>
                                </template>
                                </table>
                                <div class="product-details-price-box">
                                    <h5>Price: </h5>
                                    <div class="price-box">
                                        <p style="margin-bottom: 0px !important;" v-if="flash_deal">{{ flash_deal.flash_deal ? flash_deal.flash_deal.title : '' }}
                                            {{ flash_deal.discount_type === 'Flat' ? product.currency ? product.currency.symbol : null : '' }}
                                            {{ flash_deal.discount }} {{ flash_deal.discount_type === 'percent' ? '%' : '' }} discount</p>

                                            <p style="margin-bottom: 0px !important;" v-if="product.priceType==0">
                                            <strong>{{ product.currency ? product.currency.symbol : null }} <span
                                                v-html="(discount_price!=0 && discount_price<product.unit_price)?discount_price+' '+'<del>'+product.unit_price+'</del>':product.unit_price"></span></strong>
                                            {{
                                                product.orderQtyLimit ? ' ' + product.orderQtyLimitMin + product.unit.name + ' (Min. Order)' + '   ' + product.orderQtyLimitMax + product.unit.name + ' (Max. Order)' : ''
                                            }}
                                        </p>
                                        <p style="margin-bottom: 0px !important;" v-else-if="product.priceType==1">
                                            
                                            <strong>{{ product.currency ? product.currency.symbol : null }}
                                                <span
                                                    v-html="(discount_price!=0 && discount_price<price)?discount_price+' '+'<del>'+price+'</del>':price"></span>
                                            </strong>
                                            {{
                                                product.orderQtyLimit ? ' ' + product.orderQtyLimitMin + ' ' + product.unit.name + ' (Min. Order)' + '   ' + product.orderQtyLimitMax + ' ' + product.unit.name + ' (Max. Order)' : ''
                                            }}
                                        </p>
                                        <div v-if="product.priceType==2">
                                            <table class="table table-borderless">
                                                <tr>
                                                    <td style="text-align: center;padding: 2px" v-for="(variation,index) in product.price_variation" :key="index">
                                                        {{ variation.min_qty }} - {{ variation.max_qty }} {{ product.unit ? product.unit.name : null }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="text-align: center" v-for="(variation,key) in product.price_variation" :key="key">
                                                    <span :style="(variation.min_qty<=quantity && variation.max_qty>=quantity)?'color:red':''">
                                                    <template
                                                        v-if="(variation.min_qty<=quantity && variation.max_qty>=quantity)">{{
                                                        priceSet(tearPricingDiscount(variation.off_price), variation.off_price)}}
                                                    </template>
                                                    <strong>{{ product.currency ? product.currency.symbol : null }}
                                                        <span v-if="product.discount_variation ==0 && product.discount">{{
                                                            tearPricingDiscount(variation.off_price)
                                                        }} </span>
                                                        <span v-if="product.discount_variation ==0 && product.discount"><del>{{
                                                            '   ' + variation.off_price
                                                        }}</del></span>
                                                        <span v-else>{{ '   ' + variation.off_price }}</span>
                                                    </strong>
                                                    </span>
                                                    </td>
                                                </tr>

                                            </table>
                                            <template style="text-align: center">
                                                {{
                                                product.orderQtyLimit ? ' ' + product.orderQtyLimitMin + ' ' + product.unit.name + ' (Min. Order)' + '   ' + product.orderQtyLimitMax + ' ' + product.unit.name + ' (Max. Order)' : ''
                                                }}
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                
                                <hr>
                                <div v-for="(property,key) in property_options" :key="key">
                                    <div v-if="property.label && property.is_show">
                                        <span>{{ property.label + ': ' + property.value }}</span><br>
                                    </div>
                                </div>
                                <div v-if="colors.length>0 || color_images.length>0">
                                    <p>Color: <span style="color: green">{{ variant[0] ? variant[0] : null }}</span></p>
                                    <!--              normal color-->
                                    <template v-if="product.color_type ==0">
                                        <a class="custom_button_design" href="javascript:void(0)" v-for="(color,key) in colors" :key="key" :id="'color'+key" @click="variantChange(color,0)">{{ color }}</a>
                                    </template>
                                    <!--color is image-->
                                    <ul v-else class="list-group list-group-horizontal">
                                        <li v-for="(color,key) in color_images"
                                            :class="variant[0]===color.name?'list-group-item list-active':'list-group-item'"
                                            @click="variantChange(color.name,0)" :key="key">
                                        <img :src="showImage(color.image)" alt=""
                                            @click="imageChange(showImage(color.image))" class="img-thumbnail rounded" width="50px"
                                            height="50px">
                                        </li>
                                    </ul>
                                </div>
                                <div v-for="(attribute,key) in attribute_options" :key="key">
                                    <p>{{ attribute.name + ': ' }} <span
                                        style="color: green">{{ variant[key + 1] ? variant[key + 1] : null }}</span></p>
                                    <a class="custom_button_design" href="javascript:void(0)" v-for="(value,key) in attribute.value" :key="key"
                                        @click="variantChange(value.text,(key+1))">{{ value.text }}</a>
                                </div>
                                <hr v-if="attribute_options.length>0">
                            </div>

                            <!-- <div class="product-details-price-box">
                                <h5>Price: </h5>
                                <div class="price-box">
                                    <span><b>$700</b></span>
                                    <del>$800</del>
                                </div>
                            </div> -->

                            <div class="qty-box">
                                <h6>Quantity: </h6>
                                <div class="quantity-wrapper">
                                    <span class="input-group-prepend">
                                        <button type="button" data-type="minus" @click="quantityChange(0)" class="btn quantity-left-minus">
                                            <i aria-hidden="true" class="fa fa-minus-circle btn-outline-danger"></i>
                                        </button>
                                    </span>

                                    <input type="number" name="quantity" @change="qtyChange" v-model.number="quantity" min="1"
                                        class="form-control input-number input-quantity">

                                    <span class="input-group-prepend">
                                        <button type="button" @click="quantityChange(1)" data-type="plus" class="btn quantity-right-plus">
                                            <i aria-hidden="true" class="fa fa-plus-circle btn-outline-danger"></i>
                                        </button>
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="qty-box">
                                <p
                                    
                                    v-if="
                                    product.stockManagement == 1 &&
                                    (product.priceType == 0 || product.priceType == 2)
                                    "
                                >
                                    {{ product.quantity }}
                                    {{ product.unit ? product.unit.name : null }} available
                                </p>
                                <p
                                    
                                    v-if="
                                    product.stockManagement == 1 && product.priceType == 1
                                    "
                                >
                                    {{ available_product_qty }}
                                    {{ product.unit ? product.unit.name : null }} available
                                </p>
                            </div>
                            <hr/>
                            <div class="product-details-tag-and-social-link">
                                <!-- <div class="tag">
                                    <p class="name">tags:</p>
                                    <a href="#"
                                    class="tag-btn">Aut sunt consectetur</a>
                                </div> -->
                                <div class="social-link-wrap">
                                    <p class="name">share:</p>
                                    <div class="social-link">
                                        <ul class="social-link-list">
                                            <li class="link-item">
                                                <a href="#">
                                                    <i class="fa-brands fa-facebook-f" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li class="link-item">
                                                <a href="#">
                                                    <i class="fa-brands fa-twitter" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li class="link-item">
                                                <a href="#">
                                                    <i class="fa-brands fa-instagram" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                            <li class="link-item">
                                                <a href="#">
                                                    <i class="fa-brands fa-youtube" aria-hidden="true"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-9" v-if="related_products.length > 0">
                        <div class="container-fluid">
                          <div class="row justify-content-center">
                            <div class="container">
                              <h5>You may also like</h5>
                              <div class="row">
                                <div
                                  class="col-sm-3"
                                  v-for="(product, key) in related_products"
                                  @click="productLoad(product.slug)"
                                  :key="key"
                                >
                                  <img
                                    :src="showImage(product.thumbnail_img)"
                                    :alt="product.name"
                                    class="img-thumbnail"
                                  />
                                  <p>{{ product.name }}</p>
                                  <p>
                                    <strong
                                      >{{
                                        product.currency ? product.currency.symbol : null
                                      }}
                                      {{ relatedProductPrice(product) }}</strong
                                    ><br />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!---->
                    <div class="row">
                        <div class="justify-content-center ">
                            <div class="container  px-0">
                                <div class="row  px-0">
                                    <div class="col-md-12  px-0">
                                        <!-- RH: this is bootstrap 5 tabbed panel -->
                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a :class="activeTab=='details'?'nav-link active':'nav-link'"
                                                href="javascript:void(0)"
                                                role="tab"
                                                 @click="switchTab('details')">Product Details</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a :class="activeTab=='company'?'nav-link active':'nav-link'"
                                               href="javascript:void(0)"
                                                role="tab" @click="switchTab('company')"
                                                >Company Profile</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a :class="activeTab=='reviews'?'nav-link active':'nav-link'"
                                                href="javascript:void(0)"
                                                role="tab" @click="switchTab('reviews')"
                                                >Product Reviews</a>
                                            </li>
                                            
                                        </ul>
                                        <div class="tab-content" id="myTabContent">
                                            <div :class="activeTab=='details'?'tab-pane fade show active':'tab-pane fade'" id="product-detail" role="tabpanel"
                                                aria-labelledby="product-detail-tab">
                                                <div class="p-3 product-details-wrapper">
                                                    <div class="row p-2 border-bottom">
                                                        <div class="col-12">
                                                            <h4>Overview</h4>
                                                        </div>
                                                        <div class="col-12">Quick Details</div>
                                                        <div class="my-2 col-md-6 col-12">Title: {{ product.name }}</div>
                                                    </div>
                                                    
                                                    <b-row class="p-2 border-bottom">
                                                      <b-col cols="12">
                                                        <h6>
                                                          {{ $t("message.single_product.details") }}
                                                        </h6>
                                                        <hr />
                                                      </b-col>
                                                      <div
                                                        class="col-md-12"
                                                        v-html="product.description"
                                                      ></div>

                                                      <b-col cols="12" v-if="product.video_link">
                                                        <hr />
                                                        <h6>
                                                          {{
                                                            $t(
                                                              "message.single_product.video_description"
                                                            )
                                                          }}
                                                        </h6>
                                                        <video-embed
                                                          ref="youtube"
                                                          :src="product.video_link"
                                                          style="height: 350px; border-radius: 15px"
                                                        ></video-embed>
                                                      </b-col>
                                                      <div
                                                        class="col-md-12"
                                                        v-for="(image, key) in jsonParse(
                                                          product.photos
                                                        )"
                                                        :key="key"
                                                      >
                                                        <img
                                                          :src="showImage(image)"
                                                          alt=""
                                                          class="img-fluid rounded mx-auto d-block"
                                                        />
                                                      </div>
                                                    </b-row>
                                                    <b-row class="p-2 border-bottom">
                                                      <b-col cols="12">
                                                        <h6>
                                                          {{
                                                            $t(
                                                              "message.single_product.packaging_shipping"
                                                            )
                                                          }}
                                                        </h6>
                                                        <hr />
                                                      </b-col>
                                                      <div class="col-md-12">
                                                        <p>
                                                          1. Inner Packing : Gift box or customized
                                                          as your requirements
                                                        </p>
                                                        <p>2. Outer Packing : Master Carton</p>
                                                        <p>
                                                          3. Packing Details:16* 1080P AHD Camera/
                                                          /1*16 Channel DVR
                                                        </p>
                                                      </div>
                                                    </b-row>
                                                    <b-row class="p-2 border-bottom">
                                                      <b-col cols="12">
                                                        <h6>
                                                          {{
                                                            $t("message.single_product.our_service")
                                                          }}
                                                        </h6>
                                                        <hr />
                                                      </b-col>
                                                      <div class="col-md-12">
                                                        <p>
                                                          1. More than 10 years' experience in
                                                          surveillance products manufacturing;
                                                        </p>
                                                        <p>
                                                          2. Own one 13 professional engineer team,
                                                          capable for OEM/ODM business;
                                                        </p>
                                                        <p>3. TQM ( Total quality control);</p>
                                                        <p>
                                                          4. Professional sales team,satisfory
                                                          technical support and after-sale service.
                                                        </p>
                                                        <p>
                                                          5. Delivery time: Sample order 2-3 working
                                                          days, bulk order 7-15 working days.
                                                        </p>
                                                        <p>
                                                          6. Quality Warranty: 2 years and lifetime
                                                          technical support;
                                                        </p>
                                                      </div>
                                                    </b-row>
                                                </div>
                                            </div>
                                            <div :class="activeTab=='company'?'tab-pane fade show active':'tab-pane fade'" id="company-profile" role="tabpanel"
                                                aria-labelledby="company-profile-tab">Company Profile
                                            </div>
                                            <div :class="activeTab=='reviews'?'tab-pane fade show active':'tab-pane fade'" id="product-reviews" role="tabpanel"
                                                aria-labelledby="product-reviews-tab">
                                                <div class="client-feedback">
                                                    <ul class="comment-list">
                                                        <li v-for="(item,idx) in reviews" :key="idx">
                                                            <div class="single-comment-wrap">
                                                                <div class="content">
                                                                    <div class="content-top">
                                                                        <div class="left">
                                                                            <h4 class="title">Md Zahidul Islam</h4>
                                                                            <i class="fa fa-star fa-star"></i>
                                                                            <i class="fa fa-star icon"></i>
                                                                            <i class="fa fa-star icon"></i>
                                                                            <i class="fa fa-star icon"></i>
                                                                            <i class="fa fa-star icon"></i>
                                                                        </div>
                                                                    </div>
                                                                    <p class="comment">In publishing and graphic design,
                                                                        Lorem ipsum is a placeholder text commonly used to
                                                                        demonstrate the visual</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3 mb-5">
                    <div class="card">
                        <article class="card-body">
                            <div class="row">
                              <div class="col-md-8">
                                <div class="visit-out-site-btn-wrapper">
                                    <!-- <a href="javascript:void(0)" class="btn btn-block btn-outline-danger mb-2">
                                        <i class="fa fa-envelope mr-1"></i> Contact Seller
                                    </a> -->
                                    <a href="javascript:void(0)" @click="contactSupplier" class="btn btn-block btn-outline-danger mb-2" >
                                      <i class="far fa-envelope mr-1"></i>
                                      {{ $t("message.cart_sidebar.contact_seller") }}
                                    </a>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="visit-out-site-btn-wrapper w-100">
                                    <!-- <a href="javascript:void(0)" class="btn btn-block btn-outline-danger">
                                        <i class="fa fa-phone"></i> Call
                                    </a> -->
                                    <a href="javascript:void(0)" class="btn btn-block btn-outline-danger" >
                                      <i class="fas fa-phone-alt"></i>
                                      {{ $t("message.product_new.call") }}
                                      {{ product.user ? product.user.company_basic_info ? product.user.company_basic_info.phone : "" : "" }}
                                    </a>
                                </div>
                              </div>
                            </div>
                            
                            <div class="row">
                              <div class="col-md-8">
                                <div class="visit-out-site-btn-wrapper">
                                  <button
                                    class="btn btn-block btn-outline-danger mb-2"
                                    @click="adCart"
                                  >
                                    <i class="fa fa-shopping-cart"></i
                                    ><span>{{ $t("message.cart_sidebar.add_to_cart") }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <hr>
                            <div class="row">
                              <div class="col-md-12" style="margin-top: 5px;">
                                <div class="p-2 mb-3" style="background-color: rgb(245, 246, 252);">
                                    <img width="50px" height="50px" class="rounded"
                                        style="margin-left: 40%;" data-src=""
                                        src="" lazy="error">
                                    <div class="text-center">
                                        <h6 class="mt-2"></h6>
                                        <p class="text-muted mb-2"></p>
                                        <a
                                          href="javascript:void(0)"
                                          class="border rounded p-1 mb-2 text-white bg-secondary"
                                          @click="companyProfilePage()"
                                          >{{ $t("message.cart_sidebar.visit_store") }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import {api_base_url} from "@/core/config/app";
import {mapGetters} from "vuex";
import moment from "moment";
import {ImageMagnifier} from 'vue-image-magnifier'

import {
  PRODUCT_QUANTITY_CHANGE, PRODUCT_VARIANT, SET_PRODUCT_PRICE,
  SINGLE_PRODUCT_DETAILS,
} from "../../core/services/store/module/singleProduct";
import {REVIEW_RATING_LIST} from "../../core/services/store/module/reviews";
import {GET_CONTACT_SUPPLIER_PRODUCT} from "../../core/services/store/module/product";
import {CART_LIST} from "../../core/services/store/module/cart";


export default {
  name: "ProductDetail",
  props: {
    slug: {
      required: true,
    }
  },
  components: {
    ImageMagnifier
  },
  data() {
    return {
      moment,
      profileType: 'personal',
      current_image: null,
      quantity: 1,//selected quantity store here
      local_price: 0,
      local_discount_price: 0,
      form: new Form({
        'rating': null,
        'review': null,
      }),
      companyProfile: {},
      rating:0,
      activeTab:'details'
    }
  },
  created() {
    this.$store.dispatch(SINGLE_PRODUCT_DETAILS, this.slug);
    if (this.product) this.companyBasicInfo();
    //this.getProduct();
  },
  methods: {
    switchTab(tab){
      this.activeTab=tab
    },
    /*json data parse*/
    jsonParse(data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return [];
      }
    },
    priceSet(dis_price, price) {
      this.$store.commit(SET_PRODUCT_PRICE, price);
    },
    /*
    * method for contact supplier modal popup
    * */
    contactSupplier() {
      if (!this.isAuthenticated) {
        $('#reg').modal('hide');
        $(`#login`).modal('show');
      } else {
        this.$store.dispatch(GET_CONTACT_SUPPLIER_PRODUCT, this.product);
        $('#contact_modal').modal('show');
      }
    },
    /*
    * method for view company profile page
    * */
    companyProfilePage() {
      console.log(this.companyProfile)
      if (this.companyProfile && this.companyProfile.display_name) {
        console.log(this.companyProfile.display_name)
        this.$router.push({name: 'company-profile', params: {display_name: this.companyProfile.display_name}})
      }
    },
    companyBasicInfo() {
      ApiService.get('get/company/basic/info/'+this.product.user_id).then((response) => {
        console.log('company',response.data)
        this.companyProfile = response.data;
      }).catch((err) => {
        console.log({err})
        this.companyProfile = {};
      })
    },
    adCart() {
      let data = {
        product: this.product,
        variant: this.variant,
        quantity: this.quantity,
        price: this.price,
        discount_price: this.discount_price,
        available_product_qty: this.available_product_qty,
        flash_deal: this.flash_deal
      };
      this.$store.dispatch(CART_LIST, data);
      this.$toaster.success('Successfully added to cart');
    },
    /*
    * method for review submit
    * */
    reviewSubmit() {
      this.form.post(`user/review/store${this.product.id}`).then((response) => {
        this.form.review = null;
        swal.fire('Success', response.data, 'success');
        this.$store.dispatch(REVIEW_RATING_LIST, this.product.id);
      }).catch((error) => {
        swal.fire('Success', 'Invalid request try again', 'warning')
      });
    },
    /*
    * method for rating calculation
    * */
    ratingCalculation() {
      
      if (this.ratings) {
        let rating = this.ratings.reduce(function (sum, item) {
          return sum + Number(item.rating);
        }, 0);
        if (rating > 0) return (rating / this.ratings.length);
        return 0;
      }
      return 0;
    },
    /*
    * method for rating count by star
    * */
    ratingCount(rat) {
      if (this.ratings) {
        return this.ratings.filter((item) => {
          if (item.rating == rat) return item;
        }).length;
      }
      return 0;
    },
    /*
    * method for rating find by user id
    * */
    ratingFindByuser(user_id) {
      if (this.ratings) {
        let rat = this.ratings.filter((item) => {
          if (item.user_id == user_id) return item;
        });
        if (rat.length > 0) return rat[0].rating;
        else return 0;
      }
      return 0;
    },
    /*
    * method for reveiw count
    * */
    reviewCount() {
      if (this.reviews) {
        return this.reviews.length;
      }
      return 0;
    },
    /*
    * method for supplier phone number show
    * */
    phoneNumberShow() {
      swal.fire('Phone Number', this.company_basic.phone, 'success')
      return false;
    },
    /*
    * method for product favorite
    * */
    favorite() {
      if (!this.isAuthenticated) return;
      ApiService.post(`user-product-favorite/${this.product.id}`).then((response) => {
        this.$store.dispatch(SINGLE_PRODUCT_DETAILS, this.slug);
      });
    },
    /*
    * check favorite
    * */
    checkFavorite() {
      if (!this.isAuthenticated) return false;
      return this.product.product_favorites.filter(item => {
        if (item.user_id == this.user.id) return item;
      }).length > 0;
    },
    /*
    * method for image url set
    * */
    showImage(e) {
      // return api_base_url + e;
      return 'https://api.e-littlemart.com/public/'+e
    },
    /*
    * method for current image change
    * */
    imageChange(img) {
      this.current_image = img;
    },
    /*
    * method for selected variant set
    * */
    variantChange(variant, index) {
      this.$set(this.variant, index, variant);
      let data = {
        'index': index,
        'variant': variant,
      };
      this.$store.dispatch(PRODUCT_VARIANT, data);
      //this.discountPriceCalculate();
      this.quantity = 1;//quantity reset for variant change
    },
    /*
    * method for quantity change
    * type =0 minus
    * type =1 plus
    * */
    quantityChange(type) {
      /*
      * if order quantity limit is enable
      * */
      if (this.product.orderQtyLimit && (this.quantity <= this.product.orderQtyLimitMin || this.quantity >= this.product.orderQtyLimitMax)) {
        if (type == 0 && (this.quantity - 1) < this.product.orderQtyLimitMin) {
          this.quantity = parseInt(this.product.orderQtyLimitMin);
          return;
        } else if (type == 1 && (this.quantity + 1) > this.product.orderQtyLimitMax) {
          this.quantity = parseInt(this.product.orderQtyLimitMax);
          return;
        }
      }
      if (type == 0 && this.quantity > 1) {
        this.quantity -= 1;
      } else if (type == 1) {
        if (this.product.stockManagement == 1 && (this.product.priceType == 0 || this.product.priceType == 2)) {
          if (this.product.quantity > this.quantity) this.quantity += 1;
        } else if (this.product.stockManagement == 1 && this.product.priceType == 1) {
          if (this.available_product_qty > this.quantity) this.quantity += 1;
        } else this.quantity += 1;
      }
      this.$store.dispatch(PRODUCT_QUANTITY_CHANGE, this.quantity);
    },
    /*
    * method for on change product qty check
    * */
    qtyChange() {
      /*
      * if order quantity limit is enable
      * */
      if (this.product.orderQtyLimit && (this.quantity < this.product.orderQtyLimitMin || this.quantity > this.product.orderQtyLimitMax)) {
        if (this.quantity < this.product.orderQtyLimitMin) {
          this.quantity = parseInt(this.product.orderQtyLimitMin);
          //return;
        } else if (this.quantity > this.product.orderQtyLimitMax) {
          this.quantity = parseInt(this.product.orderQtyLimitMax);
          //return;
        }
      }
      if (this.product.stockManagement == 1 && (this.product.priceType == 0 || this.product.priceType == 2)) {
        if (this.product.quantity < this.quantity) this.quantity = parseInt(this.product.quantity);
      } else if (this.product.stockManagement == 1 && this.product.priceType == 1) {
        if (this.available_product_qty < this.quantity) this.quantity = parseInt(this.available_product_qty);
      }
      this.$store.dispatch(PRODUCT_QUANTITY_CHANGE, this.quantity);
    },
    /*
    * method for load clicked product information
    * */
    productLoad(slug) {
      this.$router.push({name: 'single.product', params: {slug: slug}})
    },
    /*
    * method for related product price rand calculate
    * */
    relatedProductPrice(product) {
      /*if (product.flash_deal_products.length>0){
        let flash = product.flash_deal_products.filter(item=>{
          if (item.flash_deal) return item;
        });
        if (flash.length>0) {
          return flash[flash.length - 1];
        }
      }else*/
      if (product.priceType == 0) {
        return product.unit_price;
      } else if (product.priceType == 1 && product.product_stock.length > 0) {
        let max = Math.max(...product.product_stock.map(d => d.price));
        let min = Math.min(...product.product_stock.map(d => d.price));
        return min + '-' + max;
      } else if (product.priceType == 2 && product.price_variation.length > 0) {
        let max = Math.max(...product.price_variation.map(d => d.off_price));
        let min = Math.min(...product.price_variation.map(d => d.off_price));
        return min + '-' + max;
      }
    },
    /*
    * method for tear pricing and discount calculation
    * */
    tearPricingDiscount(price) {
      if (this.flash_deal) {
        if (this.flash_deal.discount_type === 'Flat') {
          return (price - this.flash_deal.discount);
        } else {
          return (price - (price * (this.flash_deal.discount / 100)));
        }
      } else if (this.product.discount_variation == 0 && this.product.discount) {
        if (this.product.discount_type === 'Flat') {
          return (price - this.product.discount) < 0 ? 0 : (price - this.product.discount);
        } else {
          return price - (price * (this.product.discount / 100));
        }
      }
    },
    handleScroll() {
      if ($(window).scrollTop() > 10) {
        $(".sidebar-filter-wrapper").css({"top": "71px", "height": "88vh"});
      } else {
        $(".sidebar-filter-wrapper").css({"top": "109px", "height": "80vh"});
      }
    },
  },
  mounted(){
     console.log('rating ***',this.ratingCalculation())
      this.rating=this.ratingCalculation()
   },
  computed: {
    ...mapGetters(["isAuthenticated", "user", "product", "variant",
      "related_products", "flash_deal", "price", "discount_price", "available_product_qty",
      "company_basic", "company_details", "property_options", "attribute_options", "colors",
      "color_images", "ratings", "reviews"]),
  },
  watch: {
    product: {
      handler(newProduct) {
        this.$store.dispatch(REVIEW_RATING_LIST, newProduct.id);
        this.companyBasicInfo();
      }
    },
  }
}
</script>
<style>
    .quantity-wrapper {
        display: flex;
        width: 158px;
    }

    input.input-quantity::-webkit-outer-spin-button,
    input.input-quantity::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    div#myTabContent {
        background: #fff;
    }

    /* those line of code are only for product share link */

    .social-link-wrap {
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }

    .social-link-wrap .name {
        font-weight: bold;
    }

    .social-link .social-link-list li a {
        display: inline-block;
        color: #666666;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        background-color: #dedede;
        border-radius: 50%;
        margin: 4px 0;
        transition: .5s;
    }

    .social-link .social-link-list li a:hover .fa {
        color: white;
    }

    .social-link .social-link-list li a:hover {
        color: white;
        background: green;
    }


    .social-link .social-link-list li {
        display: inline-block;
        margin-top: -4px;
    }

    .product-details-tag-and-social-link .tag .name {
        font-weight: bold;
        margin-bottom: 0px
    }

    .product-details-tag-and-social-link .tag {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        margin-bottom: 16px;
    }

    .product-details-tag-and-social-link .tag a {
        color: #666666;
        display: inline-block;
        text-transform: capitalize;
        font-size: 12px;
        border: 1px solid #ddd;
        background-color: #ddd;
        padding: 3px 18px 4px;
        margin-right: 12px;
        border-radius: 10px;
        -webkit-transition: all linear 0.2s;
        -o-transition: all linear 0.2s;
        transition: all linear 0.2s;
    }

    .product-details-tag-and-social-link .tag .name {
        margin-right: 10px;
    }

    .product-details-tag-and-social-link {
        margin-top: 14px;
    }

    .product-details-tag-and-social-link .tag a:hover {
        background: #37a537;
        color: white;
    }

    /* Those line are for quantity wrapper */
    .qty-box {
        display: flex;
        align-items: center;
        margin: 23px 0px;
    }

    .qty-box h6 {
        font-weight: bold;
        margin: 0px;
        margin-right: 10px;
    }

    .qty-box .btn {
        border: 1px solid #ddd;
        margin: 0px 7px;
    }

    /* Product Price */
    .product-details-price-box {
        display: flex;
        align-items: center;
    }

    .product-details-price-box h5 {
        font-weight: bold;
        font-size: 16px;
        margin-right: 10px;
        margin-bottom: 0px;
    }

    .product-details-price-box .price-box del {
        color: #e50019;
        font-size: 16px;
        font-weight: bold;
    }

    .product-details-price-box .price-box span {
        font-size: 19px;
        color: #37a537;
    }

    /* Client Feedback */
    .client-feedback {
        margin-top: 50px;
    }

    .single-comment-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .client-feedback .content {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-left: 20px;
    }

    .client-feedback .single-comment-wrap .content .content-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .client-feedback .single-comment-wrap .content .content-top .left .title {
        font-family: sans-serif;
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
    }
</style>